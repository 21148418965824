import React from "react"
import { Link } from "gatsby"

const ArticleNavigation = ({ previous, next }) => {
  return (
    <ul className="flex flex-col md:flex-row -mx-2 mt-10 justify-between">
      {previous && (
        <li className="flex-1 border bg-white py-2 px-5 mx-2 mb-5 md:mb-0 hover:border-blue-500 text-gray-900 hover:text-blue-500 hover:shadow-lg">
          <Link to={previous.fields.slug} rel="prev" className="font-bold">
            <div className="flex justify-between py-5 h-full">
              <div className="text-gray-500 flex flex-col justify-center mr-5">
                <svg
                  className="fill-current h-6 w-6"
                  viewBox="0 0 448 512"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path d="M229.9 473.899l19.799-19.799c4.686-4.686 4.686-12.284 0-16.971L94.569 282H436c6.627 0 12-5.373 12-12v-28c0-6.627-5.373-12-12-12H94.569l155.13-155.13c4.686-4.686 4.686-12.284 0-16.971L229.9 38.101c-4.686-4.686-12.284-4.686-16.971 0L3.515 247.515c-4.686 4.686-4.686 12.284 0 16.971L212.929 473.9c4.686 4.686 12.284 4.686 16.971-.001z" />
                </svg>
              </div>
              <div className="text-right">
                <p className="text-gray-500 text-xs uppercase">Previous</p>
                <p>{previous.frontmatter.title}</p>
              </div>
            </div>
          </Link>
        </li>
      )}
      {next && (
        <li className="flex-1 border bg-white py-2 px-5 mx-2 hover:border-blue-500 text-gray-900 hover:text-blue-500 hover:shadow-lg">
          <Link to={next.fields.slug} rel="prev" className="font-bold">
            <div className="flex justify-between py-5 h-full">
              <div className="text-left">
                <p className="text-gray-500 text-xs uppercase">Next</p>
                <p>{next.frontmatter.title}</p>
              </div>
              <div className="text-gray-500 flex flex-col justify-center ml-5">
                <svg
                  className="fill-current h-6 w-6"
                  viewBox="0 0 448 512"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path d="M218.101 38.101L198.302 57.9c-4.686 4.686-4.686 12.284 0 16.971L353.432 230H12c-6.627 0-12 5.373-12 12v28c0 6.627 5.373 12 12 12h341.432l-155.13 155.13c-4.686 4.686-4.686 12.284 0 16.971l19.799 19.799c4.686 4.686 12.284 4.686 16.971 0l209.414-209.414c4.686-4.686 4.686-12.284 0-16.971L235.071 38.101c-4.686-4.687-12.284-4.687-16.97 0z" />
                </svg>
              </div>
            </div>
          </Link>
        </li>
      )}
    </ul>
  )
}

export default ArticleNavigation
