import React from "react"
import {graphql, Link} from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ArticleNavigation from "../components/article-navigation"

class BlogPostTemplate extends React.Component {
    render() {
        const post = this.props.data.markdownRemark
        const siteTitle = this.props.data.site.siteMetadata.title
        const {previous, next} = this.props.pageContext

        return (
            <Layout location={this.props.location} title={siteTitle}>
                <SEO
                    title={post.frontmatter.title}
                    description={post.frontmatter.description || post.excerpt}
                    twitterCard="summary_large_image"
                    meta={[
                        {
                            name: `twitter:image`,
                            content: `${this.props.data.site.siteMetadata.siteUrl}${post.fields.slug}twitter-card.jpg`,
                        },
                        {
                            property: `og:image`,
                            content: `${this.props.data.site.siteMetadata.siteUrl}${post.fields.slug}twitter-card.jpg`,
                        },
                    ]}
                />
                <section className="px-4 lg:px-0 pt-10 md:pt-24 pb-10 space-y-10">
                    <article className="prose max-w-none">
                        <div
                            className="mb-2 mt-0 flex items-center text-gray-700"
                        >
              <span class="text-gray-500">
              {post.fields.date}
              </span>
                        </div>
                        <h1 className="mb-5 font-semibold">{post.frontmatter.title}</h1>
                        <p>
                            <strong>Hey, before you start reading!</strong> I am in the market, looking for new
                            freelance employment opportunities. If you need assistance on any of your ASP.NET Core
                            projects, I am {" "}
                            <Link
                                to={`/hire/`}
                            >
                                available for hire
                            </Link>
                            {" "} for freelance work.
                        </p>
                        <div dangerouslySetInnerHTML={{__html: post.html}}/>
                    </article>
                    <div>Follow me on Twitter <a href="https://twitter.com/jerriepelser"
                                                 className="text-blue-500 hover:text-blue-600">@jerriepelser</a> for
                        tweets about coding and building a Saas.
                    </div>
                    <ArticleNavigation previous={previous} next={next}/>
                </section>
            </Layout>
        )
    }
}

export default BlogPostTemplate

export const pageQuery = graphql`
    query BlogPostBySlug($slug: String!) {
        site {
            siteMetadata {
                title
                author
                siteUrl
            }
        }
        markdownRemark(fields: { slug: { eq: $slug } }) {
            id
            excerpt(pruneLength: 160)
            html
            fields {
                slug
                date(formatString: "MMMM DD, YYYY")
            }
            frontmatter {
                title
                description
                technologies
            }
        }
    }
`
